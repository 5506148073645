.circleIcon{
  display: flex;
  border-radius: 50%;
  background-color: #3689ea;
  width: 1.4rem;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
}

.containerDad {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
   @media (max-width: 768px) {
    display: block;
   }
}

.lookForClientContainer{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  @media (max-width: 768px) {
    grid-template-columns: repeat(1,1fr);
  }
}

.headerContainerSalesHistory {
  display: flex;
  background-color: #F8FAFC;
  justify-content: space-around;
  padding: 0.5rem;
}

.none{
  display: none;
}

.headerContainerNewSale{
  display: flex;
  align-items: center;
  background-color: #F8FAFC;
  padding: 1rem 0.5rem;
  div{
    margin-right: 40%;
  }

  @media (max-width: 768px) {
    div {
      margin-right: 28%;
    }
  }
}

.searchContainer{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  gap: 1rem;
}

.closeContainer{
  display: flex;
  justify-content: start;
}

.clientSearchContainer {
  overflow-y: auto;
}

.textContainer{
  display: flex;
  flex-direction: column;
  width: 65%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.btn-actv{
  display: flex;
  padding: 0.5rem 1.2rem;
  border-radius: 1rem;
  background-color: #3689ea;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  justify-content: center;
  cursor: pointer;
  p {
    margin: 0;
  }

  @media (max-width: 768px) {
    width: 35%;
    font-size: 12px;
  }
}

.btn{
  display: flex;
  padding: 0.5rem 1.2rem;
  border-radius: 1rem;
  cursor: pointer;
  background-color: #fff;
  justify-content: center;
  p {
    margin: 0;
    color: #091057;
  }

  @media (max-width: 768px) {
    width: 35%;
    font-size: 12px;
  }
}

.itemContainer {
  display: flex;
  padding: 0.5rem 1rem;
  flex-direction: column;
}

.listContainer{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem 0.5rem;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.statusInfo{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0.2rem 0.3rem;
}

.serviceDetails {
  margin-right: 0;
}

.footerContainerSales {
  display: flex;
  align-self: end;
  justify-content: center;
  margin-top: 1rem;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;  
}

.serviceContainerNewSale {
  padding: 1rem;
}

.serviceInfoContainer {
  width: 60%;
  margin: 0 auto ;
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.paymentDetailsContainer{
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  div{
    gap: 0.5rem;
  }
}

.totalValueContainer{
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.footerContainerNewSale{
  display: flex;
  margin-top: 2rem;
}

.textNewSale {
  font-size: 20px !important;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.intervalContainer {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  background-color: #F8FAFC;
  border-radius: 0.5rem;
}

.intervalContainerSelect {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.containerInfoListPayment{
  display: flex;
  flex-direction: column;
}

.listPaymentContainer{
  width: 100%;
}

.closeIconContainer{
  display: flex;
  justify-content: end;
}

.imgContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.buttonContainerPayemnt{
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}