.header-content {
  display: flex;
  margin: 16px 8px 16px 8px;
  align-items: center;
}

.box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 95px);
  background-color: #f3f4f6;
  overflow: hidden;

  @media (max-width: 768px) {
    height: auto;
  }
}

.side-left {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 50%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: space-between;
  background-color: white;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
}

.box-content {
  display: flex;
  flex-direction: column;
  width: 92%;
  padding: 1rem 1.5rem;

  @media (max-width: 768px) {
    padding: 1rem 0.8rem;
  }
}

.side-right {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 50%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 2rem;
  gap: 6px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.hidden {
  display: none;
}

.form {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.advance-payment-days {
  display: flex;
  align-items: center;
  gap: 8px;
}

.days-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.day-item {
  display: flex;

  align-items: center;
  gap: 8px;
  width: 100%;
}

.day-item-name {
  display: flex;
  align-items: center;
  width: 180px;
  gap: 8px;
}

.day-item-time {
  display: flex;
  align-items: center;
  gap: 8px;
}