.CalendarTimeline{
  color: #595A5C;
  height: 80vh;
  padding: 1rem;
}

.rbc-event {
  min-height: 36px !important;
  width: fit-content !important;
  border: none !important;
  padding: 0.5rem !important;
  border-radius: 8px !important;
}

.rbc-today {
  background-color: transparent !important;
}

.rbc-event-content {
  font-size: 13px !important;
  font-weight: 300 !important;
}

.rbc-time-header-gutter, .rbc-row{
  display: none;
}

.rbc-label {
  padding: 0.3rem;
}

.rbc-timeslot-group{
  min-height: 80px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 800px) {
  .CalendarTimeline {
    height: 100%;
  }

  .rbc-event {
    width: 38%!important;
    padding: 0.3rem !important;
  }

  .rbc-timeslot-group{
    min-height: 100px !important;
  }
}