.componentContainer{
  display: flex;
  width: 100%;
  border-radius: 1rem;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: #FBFBFB;
  cursor: pointer;
}

.componentContainerSales{
  display: flex;
  width: 100%;
  border-radius: 1rem;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: #FAFAFA;
  cursor: pointer;
}

.serviceData{
  background-color:#fff;
  display: flex;
  flex-direction: column;
  width: 30%;
  border-radius: 1rem 0rem 0rem 1rem ;
  padding: 0.3rem;
  justify-content: center;
  align-items: center;
}

.serviceInfo{
  display: flex;
  gap: 0.2rem;
}

.serviceDetails{
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  margin-right: 1rem;
}

.paid {
  display: flex;
  flex-direction: column;
}

.statusInfo{
  display: flex;
  margin-left: auto;
  flex-direction: column;
  gap: 0.5rem;
}

.actionContainer{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-right: 1rem;
}

.servicePaymentProcessContainer{
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
}

.timePaymentProcessInfo{
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.serviceValueContainer {
  display: flex;
  align-items: center;
}

.loadingForPayment{
  display: flex;
  justify-content: center;
}