.bookingCard {
  display: flex;
  width: 100%;
  border-radius: 1rem !important;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: #fbfbfb !important;
  border: none !important;
  text-transform: none !important;
  padding: 0 0.8rem 0 0 !important;
  align-content: stretch;
  align-items: stretch !important;
  cursor: pointer;
  & .MuiButton-endIcon {
    align-items: center;
  }  
}

.bookingBoxDate {
  background-color: #3688eb;
  display: flex;
  flex-direction: column;
  width: 30%;
  border-radius: 1rem 0 0 1rem;
  padding: 0.8rem;
  justify-content: center;
  align-items: center;
}

.bookingBoxService {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 0.8rem;
}

.bookingBoxPrice {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 0.8rem;
}



