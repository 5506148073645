.blockContainer{
  display: flex;
  flex-direction: row;
  background-color: #FBFBFB;
  border-radius: 1rem;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin-bottom: 1rem;
}

.containerDate{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  padding: 1rem;
  gap: 0.3rem;
  width: 25%;
}

.clientInfoContainer{
  display: flex;
  flex-direction: column;
}

.wrapInfoClient{
  display: flex;
  align-items: center;
}

.statusContainerPayment{
  display: flex;
  padding: 0.2rem 1rem;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  background-color: #118B50;
}

.containerPayment{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-right: 0.5rem;
}

