.box-dashboard {
  display: flex;
  width: 100%;
  height: auto;
  background-color: #f3f4f6;

  @media (max-width: 768px) {
    height: auto;
  }
}

.content-dashboard {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
}

.box-reports {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
  padding: 1rem 1rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.select-person {
  width: 100%;
}

.numbers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 0.5rem;
}

.dashboard-content {
  width: 100%;
  height: 100%;
  min-height: 200px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  color: #393762;
}