.label-error {
  color: red;
  font-size: 12px;
  background-color: #FDEDED;
  padding: 0.5rem;
  border-radius: 5px;
  margin-top: 0.2rem;
  border-color: red !important;
}

.MuiInputLabel-root.Mui-focused {
  color: #3688EB !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #3688EB !important;
}

.input-error fieldset {
  border-color: red !important;
  transition: all 0.3s ease;
}

.input-helper {
  font-size: 12px;
  color: #6C757D;
  padding-left: 0.5rem;
}

.input-label {
  font-size: 14px;
  color: #6C757D;
  width: 100%;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}

.input-select {
  color: #3E3E3E;
  border-radius: 8px;
  height: 56px;

  .MuiOutlinedInput-input {
    padding: 1rem 0.5rem
  }

  .MuiSelect-select:focus {
    background-color: #F4F6F8 !important;
  }
}

.input-bg-white {
  background-color: #fff;
}

.input-select:focus {
  border-color: #3688EB;
  background-color: #F4F6F8 !important;
}

.input-checkbox svg {
  fill: #6C757D !important;
}

.input-checkbox.Mui-checked svg {
  fill: #3688EB !important;
}

.input-checkbox {
  color: #6C757D;
}

.input-checkbox:hover,
.MuiCheckbox-colorSecondary.Mui-checked:hover {
  background-color: rgba(54, 137, 234, 0.04) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked,
.MuiIconButton-colorSecondary {
  color: #3688EB !important;
}

.input-switch {
  color: #6C757D;
}

.MuiSwitch-colorSecondary.Mui-checked:hover {
  background-color: rgba(54, 137, 234, 0.04) !important;
}

.MuiSwitch-colorSecondary.Mui-checked,
.MuiIconButton-colorSecondary {
  color: #3688EB !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #7eafec !important;
}

.MuiOutlinedInput-root.Mui-disabled {
  border-color: #E9ECEF !important;
  opacity: 0.5 !important;
  transition: all 0.3s ease;
}

.MuiAutocomplete-root {
  height: auto !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px !important;
  background-color: #fff !important;
  &.input-error {
    border-color: red !important;
    transition: all 0.3s ease;
  }
  &:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
  }
  &:focus {
    border-color: #3688EB !important;
  }
  &:active {
    border-color: #3688EB !important;
  }
  &:focus-within {
    border-color: #3688EB !important;
  }
  &.MuiAutocomplete-option {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
  }
}

.MuiAutocomplete-popper {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px !important;
}

.MuiAutocomplete-input {
  padding: 1rem 1rem !important;
  border-radius: 4px !important;
  &.MuiInputBase-input {
    font-size: 14px;
    color: #6C757D;
  }
  &:focus {
    border-color: #3688EB !important;
  }
}
