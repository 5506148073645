.selectBlock {
  display: flex;
  width: 100%;
  border-radius: 1rem;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: #fbfbfb;
  border: none !important;
  text-transform: none !important;
  padding: 0.5rem 1rem !important;
}

/* Área interna que envolve dateBox, blockName e blockData */
.serviceBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1rem;
}

/* Bloco com o nome do serviço e profissional */
.blockName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
  margin-left: 1rem;
}

/* Bloco com a duração e preço */
.blockData {
  display: flex;
  gap: 1.5rem;
  margin-left: auto;
}

.grayText {
  color: #9d9d9d !important;
}
