.wrapComponentContainer{
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  background-color: #FBFBFB;
  border-radius: 1rem;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.containerLogo{
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

.iconContainer {
  display: flex;
  width: 10%;
  justify-content: center;
  align-items: center;
  background-color: #3f51b5;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem  0rem 0rem 0.5rem ;
}

.containerTransactions{
  display: flex;
  gap: 0.2rem;
}

.seeAllButton{
  display: flex;
  background-color: #3689ea;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  height: fit-content;
  padding: 0.4rem 0.5rem;
  cursor: pointer;
}

.textContainer{
  display: flex;
  margin-left: 0 !important;
}

.infoPaymentTypeContainer{
  display: flex;
}

.bookingPaymentInfo{
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  padding: 0.5rem;
  width: 100%;
  gap: 0.5rem;
}

.containerDataPaymentInfo{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0.5rem;
}

.iconBackContainer{
  display: flex;
  padding: 1rem;
  gap: 0.5rem;
  align-items: center;
}

.containerSelectedPaymentInfo{
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 0.3rem;
}

@media (max-width: 600px) {
  .seeAllButton {
    padding: 0.2rem 0.4rem;
  }
}
